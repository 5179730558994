function toggleHamburger() {
  $("#nav-toggler").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".main-nav-container").toggleClass("nav--open");
    $("body").toggleClass("nav-open-body-overlay");
    return false;
  });
}
export { toggleHamburger };

function navCloseOnClick() {
  window.addEventListener("click", (event) => {
    let body = document.querySelector(".nav-open-body-overlay");
    let navToggler = document.querySelector("#nav-toggler");
    if (event.target == body) {
      navToggler.click();
    }
  });
}
export { navCloseOnClick };


function toggleOnHover() {

  $('.children button').click(function(){
    $(this).parent().toggleClass('show');
  });

  // if (screen.width >= 992) {
    // Dropdown show
    // $('#main-menu li.children > a[href="#"], #main-menu li.children .nav-parent-toggle').on("click", function (e) {
    //   $(this).parent('.children').siblings('.children').removeClass("show");
    //   $(this).parent('.children').addClass("show");
    //   $(this).parents('nav').addClass("show");
    //   e.stopPropagation();
    //   e.preventDefault();
    // });

    // $(window).on('scroll', function (event) {
    //     var $target = $(event.target);

    //     if ($('#main-menu .children.show').length > 0) {
    //         if ($target.parents('.children').length === 0) {
    //           $('#main-menu .children').removeClass('show');
    //           $('nav').removeClass("show");
    //         }
    //     }
    // });

    // // show backdrop on hover
    // $('#main-menu li.children').hover( function(e){
    //   $(this).addClass('show');
    //   $(this).siblings().removeClass('show');
    //   // $(this).parents('nav').addClass('show');
    //   // $('html').addClass('open-nav');
    // });

    // $('nav, nav .level-1').mouseleave(function(){
    //   $(this).find('li.children.show').removeClass('show');
    //   $(this).parents('nav').removeClass('show');
    //   $('html').removeClass('open-nav');
    // });
  // } else{
  //   // Dropdown show
  //   $('#main-menu li.children > a[href="#"], #main-menu li.children .nav-parent-toggle').on("click", function (e) {
  //     $(this).parent('.children').siblings('.children').removeClass("show");
  //     $(this).parent('.children').toggleClass("show");
  //     $(this).parents('nav').toggleClass("show");
  //     e.stopPropagation();
  //     e.preventDefault();
  //   });
  // }
}
export { toggleOnHover };
