function gesundheitsMagazinSlider() {
  let owlgesundheitsMagazin = jQuery(".magazin-slider");

  owlgesundheitsMagazin.owlCarousel({
    nav: true,
    navText: [
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"> <path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>',
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>',
    ],
    mouseDrag: false,
    touchDrag: true,
    dotsEach: true,
    loop: false,
    dots: false,
    margin: 40,
    autoplay: false,
    smartSpeed: 750,
    stagePadding: 0,
    items: 3,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
        stagePadding: 10,
      },
      768: {
        items: 2,
        stagePadding: 30,
      },
      1440: {
        items: 3,
        stagePadding: 30,
      },
    },
  });
}

export { gesundheitsMagazinSlider };
