function mediShopSlider() {
  let owlMediShopSlider = jQuery(".medi-shop-slider");
  owlMediShopSlider.owlCarousel({
    loop: false,
    margin: 20,
    dots: false,
    nav: true,
    padding: 30,
    navText: [
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>',
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>'
    ],
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  });
}

export { mediShopSlider };
