import owlCarousel from "owl.carousel";

function heroSlider() {
  let owlHeroSlider = jQuery(".hero-slider-wrapper");

  owlHeroSlider.owlCarousel({
    nav: true,
    navText: [
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>',
      '<svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path d="M24,33.1l-12-12l2.1-2.2l9.9,9.9l9.8-9.9l2.2,2.2L24,33.1z"/></svg>'
    ],
    mouseDrag: false,
    touchDrag: true,
    loop: true,
    dots: false,
    // autoplayTimeout: 10000,
    // autoplay: true,
    // smartSpeed: 1000,
    stagePadding: 0,
    items: 1,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1440: {
        items: 1,
      },
    },
  });
}

export { heroSlider };
