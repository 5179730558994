function counter() {

    var a = 0;
    var counter = document.getElementById("counter");
    if (counter) {
    $(window).scroll(function () {
        var Top = $("#counter").offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > Top) {
        $(".counter-value").each(function () {
            var $this = $(this),
            countTo = $this.attr("data-count");
            $({
            countNum: $this.text(),
            }).animate(
            {
                countNum: countTo,
            },

            {
                duration: 3000,
                easing: "swing",
                step: function () {
                $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                $this.text(this.countNum);
                },
            }
            );
        });
        a = 1;
        }
    });
    }

} export { counter };
