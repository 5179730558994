/* General + Third-Party */
import jQuery from 'jquery';
import { lazyload } from './globals/lazyload';

/* Layout-Parts */
import { headerMov } from './layout/header';
import { mobileMetaNav } from "./layout/header";
import { showPopup } from "./layout/popup";
import { toggleHamburger } from "./layout/navigation";
import { navCloseOnClick } from "./layout/navigation";
import { toggleChildren } from "./layout/navigation";
import { toggleOnHover } from "./layout/navigation";


/* Blocks */
import { heroSlider } from '../../blocks/slider-hero/script';
import { gesundheitsMagazinSlider} from '../../blocks/slider-magazine/script';
import { appSlider } from '../../blocks/slider-app/script';
import { gallery} from '../../blocks/gallery/script';
import { mediShopSlider} from '../../blocks/slider-medi-shop/script';
import { accordion } from '../../blocks/accordion/script';
import { video } from '../../blocks/text-media/script';
import { counter } from '../../blocks/counter-banner/script';
import { sliderSimpleOWL } from '../../blocks/slider-simple/script';
import { slider_fancbox } from '../../blocks/slider-simple/script';


jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();

  //Layout-Parts
  headerMov();
  mobileMetaNav();
  toggleHamburger();
  navCloseOnClick();
  toggleOnHover();
  showPopup();
  counter();
  sliderSimpleOWL();
  slider_fancbox();

  //Blocks
  heroSlider();
  gesundheitsMagazinSlider();
  gallery();
  mediShopSlider();
  accordion();
  video();
  appSlider();

});
