function video() {

    $('.block-text-media').each(function(){

        $(this).find('.media').each(function(){

            $(this).find('.play-button, .video-poster').on('click', function(){
                $(this).fadeOut();
                $(this).siblings('.video-poster').fadeOut();
                $(this).siblings('.play-button').fadeOut();
                console.log('450');
                setTimeout(() => {
                    $(this).siblings('video')[0].play();
                }, 450);
            });

        });

    });

} export { video };
