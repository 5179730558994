function headerMov() {

  $(window).scroll(function(){

    var st = $(window).scrollTop();

    if ( st > 0 ) {
      $('header').addClass('fixed-small');
    } else {
      $('header').removeClass('fixed-small');
    }
  });

  var st = $(window).scrollTop();

    if ( st > 0 ) {
      $('header').addClass('fixed-small');
    } else {
      $('header').removeClass('fixed-small');
    }

  // const headerElement = document.querySelector(".navbar");
  // const mobilNavLogoContainer = document.querySelector(".navbar__logo-mobil");
  // if (headerElement) {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       headerElement.classList.add("navbar--fixed");
  //       mobilNavLogoContainer.classList.add("navbar__logo-mobil-scroll");
  //     } else {
  //       headerElement.classList.remove("navbar--fixed");
  //       mobilNavLogoContainer.classList.remove("navbar__logo-mobil-scroll");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   handleScroll();
  // }
}

export { headerMov };

function mobileMetaNav() {
  if (screen.width < 992) {
    console.log('clone');
    $('nav.navbar-meta .navbar-meta-inner').clone().appendTo('.navbar-meta-mobil');
  }
}

export {mobileMetaNav};
